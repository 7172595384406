/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import styles from "./ViewAllCosentData.module.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import PreLoader from "../../components/common/PreLoader";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/material";
import { setAppTitle } from "../../store/slices/settingsSlice";
import ProgressBar from "../../components/common/ProgressBar";
import { useSelector, useDispatch } from "react-redux";
import { apiCreateEnrolment } from "../../services/api/enrolmentApi.service";
import { apiCreateParticipant } from "../../services/api/participantApi.service";
import { setOfflineEnrolments } from "../../store/slices/offlineDataSlice";
import { apiGetEnrolment } from "../../services/api/enrolmentApi.service";
import TextField from "@mui/material/TextField";

const ViewAllCosentData = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [progressValue, setProgressValue] = useState(1);
  const { isDeviceOnline } = useSelector((state) => state.settings);
  const { offlineEnrolments } = useSelector((state) => state.offlineData);
  const [rows, setRows] = useState([]);
  const [enrolmentRecord, setEnrolmentRecord] = useState(null);
  const [pId, setPId] = useState(null);
  const [eId, setEId] = useState(null);

  const columns = [
    { field: "id", headerName: "ID", width: 50, hide: true },
    {
      field: "enrolment_date",
      headerName: "Enrolment Date",
      sortable: false,
      width: 130,
    },
    { field: "age_cat", headerName: "Age Cat", width: 150 },
    { field: "guardian", headerName: "Parent / Guardian", width: 160 },
    { field: "participant", headerName: "Participant", width: 160 },
    { field: "record_status", headerName: "Status", width: 200 },
  ];
  const ageCats = [
    "",
    "Age 15 To 17",
    "Age 15 - 17 Emancipated Minor",
    "Age 18+",
  ];

  const getEnrolment = async () => {
    const results = await apiGetEnrolment(pId, eId);

    if (results.success) {
      const data = results.body;
      console.log(data);
      setEnrolmentRecord(data);
    }
  };

  const displayField = (label, value) => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <strong>{label}:</strong> {value}
      </div>
    );
  };

  useEffect(() => {
    dispatch(setAppTitle("View Consent Data"));
    getEnrolment();
  }, []);

  // const showSnackBar = (data) => {
  //   enqueueSnackbar(data.message, {
  //     variant: data.variant,
  //   });
  // };

  // const _showSuccessMessage = (success) => {
  //   showSnackBar({
  //     message: success
  //       ? "Enrolments synchronised successfully."
  //       : "Some enrolments failed to synchronise. Please try again.",
  //     variant: "success",
  //   });
  // };

  // useEffect(() => {
  //   let _rows = [];

  //   offlineEnrolments.forEach((data, index) => {
  //     _rows.push({
  //       id: index + 1,
  //       age_cat: ageCats[data.settings.ageCat],
  //       guardian:
  //         data.enrolment.consent_guardian_first_name !== null
  //           ? `${data.enrolment.consent_guardian_first_name} ${data.enrolment.consent_guardian_first_name}`
  //           : "-",
  //       participant:
  //         data.enrolment.consent_participant_first_name !== null
  //           ? `${data.enrolment.consent_participant_first_name} ${data.enrolment.consent_participant_last_name}`
  //           : "-",
  //       enrolment_date: "23/02/2023",
  //       record_status: data.settings.recordStatus,
  //     });
  //   });

  //   setRows(_rows);
  // }, [offlineEnrolments]);

  return (
    <>
      <AppHeader isGuestMode={false} heading={`ALL CONSENT DATA`} />
      <Container maxWidth="md">
        <div className={styles.pageBoxBody}>
          <Box sx={{ mt: 1 }}>
            <center>
              <TextField
                margin="normal"
                name="pid"
                type="number"
                id="password"
                placeholder="Participant ID"
                value={pId}
                onChange={(e) => {
                  setPId(e.target.value);
                }}
              />
              &nbsp;&nbsp;
              <TextField
                margin="normal"
                name="eid"
                type="number"
                id="password"
                placeholder="Consent ID"
                value={eId}
                onChange={(e) => {
                  setEId(e.target.value);
                }}
              />
              &nbsp;&nbsp;
              <Button variant="contained" onClick={getEnrolment}>
                Search
              </Button>
            </center>
          </Box>
        </div>
        <hr />
        <br />
        {/* <div style={{ height: 600, width: "100%", marginTop: "20px" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        </div> */}
        {enrolmentRecord !== null && (
          <>
            <div style={{ marginBottom: "10px" }}>
              <strong>Participant ID:</strong>{" "}
              {`${enrolmentRecord.site_id}${enrolmentRecord.PK.split("#")[1]
                .toString()
                .padStart(4, "0")}`}
            </div>
            <div style={{ marginBottom: "10px" }}>
              <strong>Consent ID:</strong> {enrolmentRecord.SK.split("#")[1]}
            </div>
            {displayField("Site ID", enrolmentRecord.site_id)}
            {displayField(
              "Age Category",
              ageCats[enrolmentRecord.age_category]
            )}

            {displayField("Chosen Language", enrolmentRecord.chosen_language)}
            {displayField(
              "Parent/Guardian illiterate",
              enrolmentRecord.guardian_illiterate
            )}
            {displayField(
              "Parent/Guardian child_first_name",
              enrolmentRecord.consent_guardian_child_first_name
            )}
            {displayField(
              "Parent/Guardian child_last_name",
              enrolmentRecord.consent_guardian_child_last_name
            )}
            {displayField(
              "Parent/Guardian date",
              enrolmentRecord.consent_guardian_date
            )}
            {displayField(
              "Parent/Guardian first_name",
              enrolmentRecord.consent_guardian_first_name
            )}
            {displayField(
              "Parent/Guardian last_name",
              enrolmentRecord.consent_guardian_last_name
            )}
            {displayField(
              "Parent/Guardian signature",
              enrolmentRecord.consent_guardian_signature
            )}
            {displayField(
              "Parent/Guardian person obtaining date",
              enrolmentRecord.consent_guardian_staff_date
            )}
            {displayField(
              "Parent/Guardian person obtaining first_name",
              enrolmentRecord.consent_guardian_staff_first_name
            )}
            {displayField(
              "Parent/Guardian person obtaining last_name",
              enrolmentRecord.consent_guardian_staff_last_name
            )}
            {displayField(
              "Parent/Guardian person obtaining signature",
              enrolmentRecord.consent_guardian_staff_signature
            )}
            {displayField(
              "Parent/Guardian person obtaining time",
              enrolmentRecord.consent_guardian_staff_time
            )}
            {displayField(
              "Parent/Guardian time",
              enrolmentRecord.consent_guardian_time
            )}
            {displayField(
              "Parent/Guardian impartial witness date",
              enrolmentRecord.consent_guardian_witness_date
            )}
            {displayField(
              "Parent/Guardian impartial witness first_name",
              enrolmentRecord.consent_guardian_witness_first_name
            )}
            {displayField(
              "Parent/Guardian impartial witness last_name",
              enrolmentRecord.consent_guardian_witness_last_name
            )}
            {displayField(
              "Parent/Guardian impartial witness signature",
              enrolmentRecord.consent_guardian_witness_signature
            )}
            {displayField(
              "Parent/Guardian impartial witness time",
              enrolmentRecord.consent_guardian_witness_time
            )}
            <br />
            <hr />
            <br />
            {displayField(
              "Long Storage Parent/Guardian child_first_name",
              enrolmentRecord.lng_stor_guardian_child_first_name
            )}
            {displayField(
              "Long Storage Parent/Guardian child_last_name",
              enrolmentRecord.lng_stor_guardian_child_last_name
            )}
            {displayField(
              "Long Storage Parent/Guardian consent_1",
              enrolmentRecord.lng_stor_guardian_consent_1
            )}
            {displayField(
              "Long Storage Parent/Guardian consent_2",
              enrolmentRecord.lng_stor_guardian_consent_2
            )}
            {displayField(
              "Long Storage Parent/Guardian consent_3",
              enrolmentRecord.lng_stor_guardian_consent_3
            )}
            {displayField(
              "Long Storage Parent/Guardian date",
              enrolmentRecord.lng_stor_guardian_date
            )}
            {displayField(
              "Long Storage Parent/Guardian first_name",
              enrolmentRecord.lng_stor_guardian_first_name
            )}
            {displayField(
              "Long Storage Parent/Guardian last_name",
              enrolmentRecord.lng_stor_guardian_last_name
            )}
            {displayField(
              "Long Storage Parent/Guardian signature",
              enrolmentRecord.lng_stor_guardian_signature
            )}
            {displayField(
              "Long Storage Parent/Guardian person obtaining date",
              enrolmentRecord.lng_stor_guardian_staff_date
            )}
            {displayField(
              "Long Storage Parent/Guardian person obtaining first_name",
              enrolmentRecord.lng_stor_guardian_staff_first_name
            )}
            {displayField(
              "Long Storage Parent/Guardian person obtaining last_name",
              enrolmentRecord.lng_stor_guardian_staff_last_name
            )}
            {displayField(
              "Long Storage Parent/Guardian person obtaining signature",
              enrolmentRecord.lng_stor_guardian_staff_signature
            )}
            {displayField(
              "Long Storage Parent/Guardian person obtaining time",
              enrolmentRecord.lng_stor_guardian_staff_time
            )}
            {displayField(
              "Long Storage Parent/Guardian time",
              enrolmentRecord.lng_stor_guardian_time
            )}
            {displayField(
              "Long Storage Parent/Guardian impartial witness date",
              enrolmentRecord.lng_stor_guardian_witness_date
            )}
            {displayField(
              "Long Storage Parent/Guardian impartial witness first_name",
              enrolmentRecord.lng_stor_guardian_witness_first_name
            )}
            {displayField(
              "Long Storage Parent/Guardian impartial witness last_name",
              enrolmentRecord.lng_stor_guardian_witness_last_name
            )}
            {displayField(
              "Long Storage Parent/Guardian impartial witness signature",
              enrolmentRecord.lng_stor_guardian_witness_signature
            )}
            {displayField(
              "Long Storage Parent/Guardian impartial witness time",
              enrolmentRecord.lng_stor_guardian_witness_time
            )}

            <br />
            <hr />
            <br />

            {displayField(
              "Participant illiterate",
              enrolmentRecord.participant_illiterate
            )}
            {displayField(
              "Participant date",
              enrolmentRecord.consent_participant_date
            )}
            {displayField(
              "Participant time",
              enrolmentRecord.consent_participant_time
            )}
            {displayField(
              "Participant first_name",
              enrolmentRecord.consent_participant_first_name
            )}
            {displayField(
              "Participant last_name",
              enrolmentRecord.consent_participant_last_name
            )}
            {displayField(
              "Participant signature",
              enrolmentRecord.consent_participant_signature
            )}
            {displayField(
              "Participant person obtaining date",
              enrolmentRecord.consent_participant_staff_date
            )}
            {displayField(
              "Participant person obtaining first_name",
              enrolmentRecord.consent_participant_staff_first_name
            )}
            {displayField(
              "Participant person obtaining last_name",
              enrolmentRecord.consent_participant_staff_last_name
            )}
            {displayField(
              "Participant person obtaining signature",
              enrolmentRecord.consent_participant_staff_signature
            )}
            {displayField(
              "Participant person obtaining time",
              enrolmentRecord.consent_participant_staff_time
            )}

            {displayField(
              "Participant impartial witness date",
              enrolmentRecord.consent_participant_witness_date
            )}
            {displayField(
              "Participant impartial witness first_name",
              enrolmentRecord.consent_participant_witness_first_name
            )}
            {displayField(
              "Participant impartial witness last_name",
              enrolmentRecord.consent_participant_witness_last_name
            )}
            {displayField(
              "Participant impartial witness signature",
              enrolmentRecord.consent_participant_witness_signature
            )}
            {displayField(
              "Participant impartial witness time",
              enrolmentRecord.consent_participant_witness_time
            )}
            {/* {displayField("created_date", enrolmentRecord.created_date)}
            {displayField("created_time", enrolmentRecord.created_time)} */}
            <br />
            <hr />
            <br />
            {displayField(
              "Long Storage Participant consent_1",
              enrolmentRecord.lng_stor_participant_consent_1
            )}
            {displayField(
              "Long Storage Participant consent_2",
              enrolmentRecord.lng_stor_participant_consent_2
            )}
            {displayField(
              "Long Storage Participant consent_3",
              enrolmentRecord.lng_stor_participant_consent_3
            )}
            {displayField(
              "Long Storage Participant date",
              enrolmentRecord.lng_stor_participant_date
            )}
            {displayField(
              "Long Storage Participant first_name",
              enrolmentRecord.lng_stor_participant_first_name
            )}
            {displayField(
              "Long Storage Participant last_name",
              enrolmentRecord.lng_stor_participant_last_name
            )}
            {displayField(
              "Long Storage Participant signature",
              enrolmentRecord.lng_stor_participant_signature
            )}
            {displayField(
              "Long Storage Participant person obtaining date",
              enrolmentRecord.lng_stor_participant_staff_date
            )}
            {displayField(
              "Long Storage Participant person obtaining first_name",
              enrolmentRecord.lng_stor_participant_staff_first_name
            )}
            {displayField(
              "Long Storage Participant person obtaining last_name",
              enrolmentRecord.lng_stor_participant_staff_last_name
            )}
            {displayField(
              "Long Storage Participant person obtaining signature",
              enrolmentRecord.lng_stor_participant_staff_signature
            )}
            {displayField(
              "Long Storage Participant person obtaining time",
              enrolmentRecord.lng_stor_participant_staff_time
            )}
            {displayField(
              "Long Storage Participant time",
              enrolmentRecord.lng_stor_participant_time
            )}
            {displayField(
              "Long Storage Participant impartial witness date",
              enrolmentRecord.lng_stor_participant_witness_date
            )}
            {displayField(
              "Long Storage Participant impartial witness first_name",
              enrolmentRecord.lng_stor_participant_witness_first_name
            )}
            {displayField(
              "Long Storage Participant impartial witness last_name",
              enrolmentRecord.lng_stor_participant_witness_last_name
            )}
            {displayField(
              "Long Storage Participant impartial witness signature",
              enrolmentRecord.lng_stor_participant_witness_signature
            )}
            {displayField(
              "Long Storage Participant impartial witness time",
              enrolmentRecord.lng_stor_participant_witness_time
            )}

            {/* {displayField("screen_times", enrolmentRecord.screen_times)} */}
          </>
        )}
      </Container>

      <PreLoader show={Loading} />
    </>
  );
};

export default ViewAllCosentData;
