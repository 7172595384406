import React from "react";
import styles from "./ConsentImpartialWitnessView.module.css";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";

const ConsentImpartialWitnessView = ({ impartialWitnessObj, pageName }) => {
  return (
    <>
      <div className={styles.pageViewBox}>
        {pageName === "MINOR_PARENT_CONSENT" && (
          <>
            <h3 className={styles.intro}>
              Impartial witness (if parent / guardian is illiterate)
            </h3>
            <p>
              I sign here as a witness to the consent process. I have
              participated in the discussion and witnessed the participant’s
              consent as above to screened and assessed for eligibility to
              participate in the study:
            </p>
          </>
        )}
        {pageName === "MINOR_PARENT_LONG_STORAGE" && (
          <>
            <h3 className={styles.intro}>
              Impartial witness (if parent / guardian is illiterate)
            </h3>
            <p>
              I sign here as a witness to the consent process. I have
              participated in the discussion and witnessed the participant’s
              consent as above to screened and assessed for eligibility to
              participate in the study:
            </p>
          </>
        )}
        {pageName === "MINOR_PARTICIPANT_CONSENT" && (
          <>
            <h3 className={styles.intro}>
              Impartial witness (if participant is illiterate)
            </h3>
            <p>
              I sign here as a witness to the assent process. I have
              participated in the discussion and witnessed the participant’s
              assent as above to study participation:
            </p>
          </>
        )}

        {pageName === "MINOR_PARTICIPANT_LONG_STORAGE" && (
          <>
            <h3 className={styles.intro}>
              Impartial witness (if participant is illiterate)
            </h3>
            <p>
              I sign here as a witness to the assent process. I have
              participated in the discussion and witnessed the participant’s
              assent as above to study participation:
            </p>
          </>
        )}

        {(pageName === "EMANCIPATED_MINOR_CONSENT" ||
          pageName === "ADULT_CONSENT") && (
          <>
            <h3 className={styles.intro}>
              Impartial witness (if participant is illiterate)
            </h3>
            <p>
              I sign here as a witness to the consent process. I have
              participated in the discussion and witnessed the participant’s
              consent as above to study participation:
            </p>
          </>
        )}

        {(pageName === "EMANCIPATED_MINOR_LONG_STORAGE" ||
          pageName === "ADULT_LONG_STORAGE") && (
          <>
            <h3 className={styles.intro}>
              Impartial witness (if participant is illiterate)
            </h3>
            <p>
              I sign here as a witness to the consent process. I have
              participated in the discussion and witnessed the participant’s
              consent as above to study participation:
            </p>
          </>
        )}

        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Impartial Witness First Name </label>
                <div className={styles.fieldText}>
                  {impartialWitnessObj.witnessFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Impartial Witness Last Name</label>
                <div className={styles.fieldText}>
                  {impartialWitnessObj.witnessLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Impartial Witness Signature </label>
                <div className={styles.signatureImageBox}>
                  <img
                    width="100%"
                    src={impartialWitnessObj.witnessSignature}
                    alt="Signature box"
                  />
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Date</label>
                <div className={styles.fieldText}>
                  {impartialWitnessObj.consentDate}
                </div>
              </FormControl>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Time</label>
                <div className={styles.fieldText}>
                  {impartialWitnessObj.consentTime}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default ConsentImpartialWitnessView;
