import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { apiGetEnrolments } from "../../../services/api/enrolmentApi.service";
import PreLoader from "../../../components/common/PreLoader";
import { useSelector, useDispatch } from "react-redux";
import { setAgeCat } from "../../../store/slices/settingsSlice";
import {
  setMinorParentConsent,
  setMinorParentLongStorage,
  setMinorParticipantConsent,
  setMinorParticipantLongStorage,
} from "../../../store/slices/enrolmentSlice";

const TabAllConsents = ({ ageCat = "" }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [enrolments, setEnrolments] = useState([]);
  const [Loading, setLoading] = useState(false);
  const columns = [
    { field: "id", headerName: "ID", width: 50, hide: true },
    {
      field: "full_enrolment_id",
      headerName: "Consent ID",
      width: 130,
    },
    {
      field: "full_participant_id",
      headerName: "Participant ID",
      width: 130,
    },
    {
      field: "site_id",
      headerName: "Site ID",
      width: 130,
    },
    {
      field: "enrolment_date",
      headerName: "Enrolment Date",
      width: 130,
    },
    {
      field: "chosen_language",
      headerName: "Chosen Language",
      width: 185,
    },
    { field: "age_cat", headerName: "Age Cat", width: 250 },
    {
      field: "guardian_illiterate",
      headerName: "Parent Illiterate",
      width: 185,
    },
    {
      field: "consent_guardian_child_first_name",
      headerName: "Child First Name",
      width: 160,
    },
    {
      field: "consent_guardian_child_last_name",
      headerName: "Child Last Name",
      width: 160,
    },
    {
      field: "consent_guardian_first_name",
      headerName: "Parent  First Name",
      width: 160,
    },
    {
      field: "consent_guardian_last_name",
      headerName: "Parent Last Name",
      width: 160,
    },
    {
      field: "consent_guardian_date",
      headerName: "Parent Consent Date",
      width: 185,
    },
    {
      field: "consent_guardian_time",
      headerName: "Parent Consent Time",
      width: 185,
    },
    {
      field: "consent_guardian_witness_first_name",
      headerName: "Parent Witness First Name",
      width: 185,
    },
    {
      field: "consent_guardian_witness_last_name",
      headerName: "Parent Witness Last Name",
      width: 185,
    },
    {
      field: "consent_guardian_witness_date",
      headerName: "Parent Witness Date",
      width: 185,
    },
    {
      field: "consent_guardian_witness_time",
      headerName: "Parent Witness Time",
      width: 185,
    },
    {
      field: "consent_guardian_staff_first_name",
      headerName: "Parent Person Obtaining First Name",
      width: 250,
    },
    {
      field: "consent_guardian_staff_last_name",
      headerName: "Parent Person Obtaining First Name",
      width: 250,
    },
    {
      field: "consent_guardian_staff_date",
      headerName: "Parent Person Obtaining Date",
      width: 220,
    },
    {
      field: "consent_guardian_staff_time",
      headerName: "Parent Person Obtaining Time",
      width: 220,
    },
    //
    {
      field: "lng_stor_guardian_consent_1",
      headerName: "Long Storage Q1",
      width: 145,
    },
    {
      field: "lng_stor_guardian_consent_2",
      headerName: "Long Storage Q2",
      width: 145,
    },
    {
      field: "lng_stor_guardian_consent_3",
      headerName: "Long Storage Q3",
      width: 145,
    },
    {
      field: "lng_stor_guardian_child_first_name",
      headerName: "Long Storage Child First Name",
      width: 220,
    },
    {
      field: "lng_stor_guardian_child_last_name",
      headerName: "Long Storage Child Last Name",
      width: 220,
    },
    {
      field: "lng_stor_guardian_first_name",
      headerName: "Long Storage Parent First Name",
      width: 280,
    },
    {
      field: "lng_stor_guardian_last_name",
      headerName: "Long Storage Parent Last Name",
      width: 280,
    },
    {
      field: "lng_stor_guardian_date",
      headerName: "Long Storage Parent Consent Date",
      width: 280,
    },
    {
      field: "lng_stor_guardian_time",
      headerName: "Long Storage Parent Consent Time",
      width: 280,
    },
    {
      field: "lng_stor_guardian_witness_first_name",
      headerName: "Long Storage Parent Witness First Name",
      width: 320,
    },
    {
      field: "lng_stor_guardian_witness_last_name",
      headerName: "Long Storage Parent Witness Last Name",
      width: 320,
    },
    {
      field: "lng_stor_guardian_witness_date",
      headerName: "Long Storage Parent Witness Date",
      width: 320,
    },
    {
      field: "lng_stor_guardian_witness_time",
      headerName: "Long Storage Parent Witness Time",
      width: 320,
    },
    {
      field: "lng_stor_guardian_staff_first_name",
      headerName: "Long Storage Parent Person Obtaining First Name",
      width: 340,
    },
    {
      field: "lng_stor_guardian_staff_last_name",
      headerName: "Long Storage Parent Person Obtaining First Name",
      width: 340,
    },
    {
      field: "lng_stor_guardian_staff_date",
      headerName: "Long Storage Parent Person Obtaining Date",
      width: 320,
    },
    {
      field: "lng_stor_guardian_staff_time",
      headerName: "Long Storage Parent Person Obtaining Time",
      width: 320,
    },
    /////

    {
      field: "consent_participant_first_name",
      headerName: "Participant First Name",
      width: 160,
    },
    {
      field: "consent_participant_last_name",
      headerName: "Participant Last Name",
      width: 160,
    },
    {
      field: "consent_participant_date",
      headerName: "Participant Consent Date",
      width: 185,
    },
    {
      field: "consent_participant_time",
      headerName: "Participant Consent Time",
      width: 185,
    },
    {
      field: "consent_participant_witness_first_name",
      headerName: "Participant Witness First Name",
      width: 185,
    },
    {
      field: "consent_participant_witness_last_name",
      headerName: "Participant Witness Last Name",
      width: 185,
    },
    {
      field: "consent_participant_witness_date",
      headerName: "Participant Witness Date",
      width: 185,
    },
    {
      field: "consent_participant_witness_time",
      headerName: "Participant Witness Time",
      width: 185,
    },
    {
      field: "consent_participant_staff_first_name",
      headerName: "Participant Person Obtaining First Name",
      width: 250,
    },
    {
      field: "consent_participant_staff_last_name",
      headerName: "Participant Person Obtaining First Name",
      width: 250,
    },
    {
      field: "consent_participant_staff_date",
      headerName: "Participant Person Obtaining Date",
      width: 220,
    },
    {
      field: "consent_participant_staff_time",
      headerName: "Participant Person Obtaining Time",
      width: 220,
    },
    //
    {
      field: "lng_stor_participant_consent_1",
      headerName: "Long Storage Q1",
      width: 145,
    },
    {
      field: "lng_stor_participant_consent_2",
      headerName: "Long Storage Q2",
      width: 145,
    },
    {
      field: "lng_stor_participant_consent_3",
      headerName: "Long Storage Q3",
      width: 145,
    },
    {
      field: "lng_stor_participant_first_name",
      headerName: "Long Storage Participant First Name",
      width: 280,
    },
    {
      field: "lng_stor_participant_last_name",
      headerName: "Long Storage Participant Last Name",
      width: 280,
    },
    {
      field: "lng_stor_participant_date",
      headerName: "Long Storage Participant Consent Date",
      width: 280,
    },
    {
      field: "lng_stor_participant_time",
      headerName: "Long Storage Participant Consent Time",
      width: 280,
    },
    {
      field: "lng_stor_participant_witness_first_name",
      headerName: "Long Storage Participant Witness First Name",
      width: 320,
    },
    {
      field: "lng_stor_participant_witness_last_name",
      headerName: "Long Storage Participant Witness Last Name",
      width: 320,
    },
    {
      field: "lng_stor_participant_witness_date",
      headerName: "Long Storage Participant Witness Date",
      width: 320,
    },
    {
      field: "lng_stor_participant_witness_time",
      headerName: "Long Storage Participant Witness Time",
      width: 320,
    },
    {
      field: "lng_stor_participant_staff_first_name",
      headerName: "Long Storage Participant Person Obtaining First Name",
      width: 340,
    },
    {
      field: "lng_stor_participant_staff_last_name",
      headerName: "Long Storage Participant Person Obtaining First Name",
      width: 340,
    },
    {
      field: "lng_stor_participant_staff_date",
      headerName: "Long Storage Participant Person Obtaining Date",
      width: 320,
    },
    {
      field: "lng_stor_participant_staff_time",
      headerName: "Long Storage Participant Person Obtaining Time",
      width: 320,
    },
    { field: "record_status", headerName: "Status", width: 200 },
  ];

  const ageCats = [
    "",
    "Age 15 To 17",
    "Age 15 - 17 Emancipated Minor",
    "Age 18+",
  ];

  const getEnrolments = async () => {
    setLoading(true);
    const results = await apiGetEnrolments();

    if (results.success) {
      let data = results.body;

      if (ageCat !== "") {
        data = data.filter((item) => item.age_category === ageCat);
      }

      console.log(data);
      setEnrolments(data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEnrolments();
  }, []);

  useEffect(() => {
    if (enrolments.length > 0) {
      let _rows = [];

      enrolments.forEach((data, index) => {
        _rows.push({
          id: index + 1,
          PK: data.PK,
          SK: data.SK,
          age_cat: ageCats[data.age_category],
          full_participant_id:
            data.site_id + data.PK.replace("PARTICIPANT#", "").padStart(4, "0"),
          full_enrolment_id: data.SK.replace("ENROLMENT#", ""),
          age_category: data.age_category,
          chosen_language: data.chosen_language,
          consent_guardian_child_first_name:
            data.consent_guardian_child_first_name,
          consent_guardian_child_last_name:
            data.consent_guardian_child_last_name,
          consent_guardian_date: data.consent_guardian_date,
          consent_guardian_first_name: data.consent_guardian_first_name,
          consent_guardian_last_name: data.consent_guardian_last_name,
          consent_guardian_signature: data.consent_guardian_signature,
          consent_guardian_staff_date: data.consent_guardian_staff_date,
          consent_guardian_staff_first_name:
            data.consent_guardian_staff_first_name,
          consent_guardian_staff_last_name:
            data.consent_guardian_staff_last_name,
          consent_guardian_staff_signature:
            data.consent_guardian_staff_signature,
          consent_guardian_staff_time: data.consent_guardian_staff_time,
          consent_guardian_time: data.consent_guardian_time,
          consent_guardian_witness_date: data.consent_guardian_witness_date,
          consent_guardian_witness_first_name:
            data.consent_guardian_witness_first_name,
          consent_guardian_witness_last_name:
            data.consent_guardian_witness_last_name,
          consent_guardian_witness_signature:
            data.consent_guardian_witness_signature,
          consent_guardian_witness_time: data.consent_guardian_witness_time,
          consent_participant_date: data.consent_participant_date,
          consent_participant_first_name: data.consent_participant_first_name,
          consent_participant_last_name: data.consent_participant_last_name,
          consent_participant_signature: data.consent_participant_signature,
          consent_participant_staff_date: data.consent_participant_staff_date,
          consent_participant_staff_first_name:
            data.consent_participant_staff_first_name,
          consent_participant_staff_last_name:
            data.consent_participant_staff_last_name,
          consent_participant_staff_signature:
            data.consent_participant_staff_signature,
          consent_participant_staff_time: data.consent_participant_staff_time,
          consent_participant_time: data.consent_participant_time,
          consent_participant_witness_date:
            data.consent_participant_witness_date,
          consent_participant_witness_first_name:
            data.consent_participant_witness_first_name,
          consent_participant_witness_last_name:
            data.consent_participant_witness_last_name,
          consent_participant_witness_signature:
            data.consent_participant_witness_signature,
          consent_participant_witness_time:
            data.consent_participant_witness_time,
          created_date: data.created_date,
          created_time: data.created_time,
          guardian_aou_results: data.guardian_aou_results,
          guardian_illiterate: data.guardian_illiterate ? "YES" : "NO",
          lng_stor_guardian_child_first_name:
            data.lng_stor_guardian_child_first_name,
          lng_stor_guardian_child_last_name:
            data.lng_stor_guardian_child_last_name,
          lng_stor_guardian_consent_1: data.lng_stor_guardian_consent_1,
          lng_stor_guardian_consent_2: data.lng_stor_guardian_consent_2,
          lng_stor_guardian_consent_3: data.lng_stor_guardian_consent_3,
          lng_stor_guardian_date: data.lng_stor_guardian_date,
          lng_stor_guardian_first_name: data.lng_stor_guardian_first_name,
          lng_stor_guardian_last_name: data.lng_stor_guardian_last_name,
          lng_stor_guardian_signature: data.lng_stor_guardian_signature,
          lng_stor_guardian_staff_date: data.lng_stor_guardian_staff_date,
          lng_stor_guardian_staff_first_name:
            data.lng_stor_guardian_staff_first_name,
          lng_stor_guardian_staff_last_name:
            data.lng_stor_guardian_staff_last_name,
          lng_stor_guardian_staff_signature:
            data.lng_stor_guardian_staff_signature,
          lng_stor_guardian_staff_time: data.lng_stor_guardian_staff_time,
          lng_stor_guardian_time: data.lng_stor_guardian_time,
          lng_stor_guardian_witness_date: data.lng_stor_guardian_witness_date,
          lng_stor_guardian_witness_first_name:
            data.lng_stor_guardian_witness_first_name,
          lng_stor_guardian_witness_last_name:
            data.lng_stor_guardian_witness_last_name,
          lng_stor_guardian_witness_signature:
            data.lng_stor_guardian_witness_signature,
          lng_stor_guardian_witness_time: data.lng_stor_guardian_witness_time,
          lng_stor_participant_consent_1: data.lng_stor_participant_consent_1,
          lng_stor_participant_consent_2: data.lng_stor_participant_consent_2,
          lng_stor_participant_consent_3: data.lng_stor_participant_consent_3,
          lng_stor_participant_date: data.lng_stor_participant_date,
          lng_stor_participant_first_name: data.lng_stor_participant_first_name,
          lng_stor_participant_last_name: data.lng_stor_participant_last_name,
          lng_stor_participant_signature: data.lng_stor_participant_signature,
          lng_stor_participant_staff_date: data.lng_stor_participant_staff_date,
          lng_stor_participant_staff_first_name:
            data.lng_stor_participant_staff_first_name,
          lng_stor_participant_staff_last_name:
            data.lng_stor_participant_staff_last_name,
          lng_stor_participant_staff_signature:
            data.lng_stor_participant_staff_signature,
          lng_stor_participant_staff_time: data.lng_stor_participant_staff_time,
          lng_stor_participant_time: data.lng_stor_participant_time,
          lng_stor_participant_witness_date:
            data.lng_stor_participant_witness_date,
          lng_stor_participant_witness_first_name:
            data.lng_stor_participant_witness_first_name,
          lng_stor_participant_witness_last_name:
            data.lng_stor_participant_witness_last_name,
          lng_stor_participant_witness_signature:
            data.lng_stor_participant_witness_signature,
          lng_stor_participant_witness_time:
            data.lng_stor_participant_witness_time,
          metadata: data.metadata,
          participant_id: data.participant_id,
          participant_illiterate: data.participant_illiterate ? "YES" : "NO",
          screen_times: data.screen_times,
          site_id: data.site_id,
          enrolment_date: data.consent_participant_date,
          record_status: data.record_status,
        });
      });
      setRows(_rows);
    }
  }, [enrolments]);

  const handleRowSelection = (e) => {
    const _PK = e[0];
    const _currentRecord = rows.filter((item) => item.id === _PK)[0];
    console.log(_PK, _currentRecord);

    dispatch(setAgeCat(parseInt(_currentRecord.age_category)));

    dispatch(
      setMinorParentConsent({
        childFirstName: _currentRecord.consent_guardian_child_first_name,
        childLastName: _currentRecord.consent_guardian_child_last_name,
        parentFirstName: _currentRecord.consent_guardian_first_name,
        parentLastName: _currentRecord.consent_guardian_last_name,
        parentSignature: _currentRecord.consent_guardian_signature,
        isSigned: _currentRecord.guardian_illiterate === "NO",
        consentDate: _currentRecord.consent_guardian_date,
        consentTime: _currentRecord.consent_guardian_time,
      })
    );

    dispatch(
      setMinorParentLongStorage({
        consent1: _currentRecord.lng_stor_guardian_consent_1,
        consent2: _currentRecord.lng_stor_guardian_consent_2,
        consent3: _currentRecord.lng_stor_guardian_consent_3,
        childFirstName: _currentRecord.lng_stor_guardian_child_first_name,
        childLastName: _currentRecord.lng_stor_guardian_child_last_name,
        parentFirstName: _currentRecord.lng_stor_guardian_first_name,
        parentLastName: _currentRecord.lng_stor_guardian_last_name,
        parentSignature: _currentRecord.lng_stor_guardian_signature,
        isSigned: _currentRecord.guardian_illiterate === "NO",
        consentDate: _currentRecord.lng_stor_guardian_date,
        consentTime: _currentRecord.lng_stor_guardian_time,
      })
    );

    dispatch(
      setMinorParticipantConsent({
        participantFirstName: _currentRecord.consent_participant_first_name,
        participantLastName: _currentRecord.consent_participant_last_name,
        participantSignature: _currentRecord.consent_participant_signature,
        isSigned: _currentRecord.participant_illiterate === "NO",
        consentDate: _currentRecord.consent_participant_date,
        consentTime: _currentRecord.consent_participant_time,
      })
    );

    dispatch(
      setMinorParticipantLongStorage({
        consent1: _currentRecord.lng_stor_participant_consent_1,
        consent2: _currentRecord.lng_stor_participant_consent_2,
        consent3: _currentRecord.lng_stor_participant_consent_3,
        participantFirstName: _currentRecord.lng_stor_participant_first_name,
        participantLastName: _currentRecord.lng_stor_participant_last_name,
        participantSignature: _currentRecord.lng_stor_participant_signature,
        isSigned: _currentRecord.participant_illiterate === "NO",
        consentDate: _currentRecord.lng_stor_participant_date,
        consentTime: _currentRecord.lng_stor_participant_time,
      })
    );

    // dispatch(
    //   setPersonObtainingForm({
    //     personObtainingFirstName: values.personObtainingFirstName,
    //     personObtainingLastName: values.personObtainingLastName,
    //     personObtainingSignature: personObtainingSignature,
    //     isSigned: isSigned,
    //     consentDate: todayDate,
    //     consentTime: todayTime,
    //   })
    // );

    history.push("/consent-print-preview");

  };

  return (
    <Box sx={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection={false}
        onSelectionModelChange={handleRowSelection}
      />
      <PreLoader show={Loading} />
    </Box>
  );
};

TabAllConsents.propTypes = {
  ageCat: PropTypes.any,
};

export default TabAllConsents;
