import React from "react";
import styles from "./ConsentPersonObtainingView.module.css";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";

const ConsentPersonObtainingView = ({ personObtainingObj }) => {
  return (
    <>
      <div className={styles.pageViewBox}>
        <h3 className={styles.intro}>PERSON OBTAINING CONSENT</h3>
        <p>
          I have fully explained the nature and purpose of the above-described
          study and the risks and benefits that are involved in its performance.
          I have answered all questions to the best of my ability.
        </p>

        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Person Obtaining Consent First Name</label>
                <div className={styles.fieldText}>
                  {personObtainingObj.personObtainingFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Person Obtaining Consent Last Name</label>
                <div className={styles.fieldText}>
                  {personObtainingObj.personObtainingLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Person Obtaining Consent Signature</label>
                <div className={styles.signatureImageBox}>
                  <img
                    width="100%"
                    src={personObtainingObj.personObtainingSignature}
                    alt="Signature box"
                  />
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Date</label>
                <div className={styles.fieldText}>
                  {personObtainingObj.consentDate}
                </div>
              </FormControl>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Time</label>
                <div className={styles.fieldText}>
                  {personObtainingObj.consentTime}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

ConsentPersonObtainingView.propTypes = {
  personObtainingObj: PropTypes.object,
};

export default ConsentPersonObtainingView;
