/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styles from "./AppHeader.module.css";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { Auth } from "aws-amplify";
import Logout from "@mui/icons-material/Logout";
import { useHistory } from "react-router-dom";
import { signOut } from "../../services/users.service";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import HandleSaveEnrolment from "../Renderless/HandleSaveEnrolment";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { setLoggedInUser } from "../../store/slices/settingsSlice";
import { resetSettings } from "../../store/slices/settingsSlice";
import { resetImpartialWitnessForm } from "../../store/slices/impartialWitnessFormSlice";
import { resetPersonObtainingForm } from "../../store/slices/personObtainingFormSlice";
import { resetAou } from "../../store/slices/aouSlice";
import { resetEnrolment } from "../../store/slices/enrolmentSlice";
import { setTriggerEvent } from "../../store/slices/settingsSlice";
import { IAVILogoBase64 } from "../../data/image_assets";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";

const AppHeader = ({
  isGuestMode,
  heading = "",
  isPrintPreviewMode = false,
}) => {
  const [profileMenu, setProfileMenu] = React.useState(null);
  const openProfileMenu = Boolean(profileMenu);
  const [langMenu, setLangMenu] = React.useState(null);
  const openLangMenu = Boolean(langMenu);
  const history = useHistory();
  const [userFullName, setUserFullName] = useState("");
  const [userInitials, setUserInitials] = useState("");
  const [siteId, setSiteId] = useState("");
  const [t, i18n] = useTranslation("common");
  const [open, setOpen] = React.useState(false);
  const [languages] = useState([
    {
      languageName: "English",
      languageCode: "en_ZM",
    },
    {
      languageName: "Bemba",
      languageCode: "bem_ZM",
    },
  ]);
  const dispatch = useDispatch();

  const { isDeviceOnline, loggedInUser, appTitle } = useSelector(
    (state) => state.settings
  );

  const { participant_id, enrolment_id } = useSelector(
    (state) => state.enrolment
  );

  const handleClickOpen = () => {
    const url = window.location.pathname;
    if (url === "/new-enrolment") {
      setOpen(true);
    } else {
      history.push({ pathname: "/home" });
    }
  };

  const handleClickBack = () => {
    window.history.back();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProfileMenuClick = (event) => {
    setProfileMenu(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileMenu(null);
  };

  const handleLangMenuClick = (event) => {
    setLangMenu(event.currentTarget);
  };
  const handleLangMenuClose = () => {
    setLangMenu(null);
  };

  const logout = async () => {
    await signOut();
    dispatch(resetSettings());
    dispatch(resetImpartialWitnessForm());
    dispatch(resetPersonObtainingForm());
    dispatch(resetAou());
    dispatch(resetEnrolment());
    dispatch(setTriggerEvent(""));
    dispatch(setLoggedInUser(null));
    localStorage.removeItem("MAGY.LOCAL");
    localStorage.removeItem("i18nextLng");
    localStorage.removeItem("persist:root");
    history.push({ pathname: "/login" });
  };

  const goHome = () => {
    history.push({ pathname: "/end-of-enrolment" });
  };

  const handlePrint = () => {
    window.print();
  };

  const setUserDetails = () => {
    setSiteId(loggedInUser.siteId);
    setUserFullName(`${loggedInUser.firstName} ${loggedInUser.lastName}`);
    setUserInitials(`${loggedInUser.firstName[0]}${loggedInUser.lastName[0]}`);
  };

  const displayParticipantId = () => {
    return loggedInUser.siteId + participant_id.padStart(4, "0");
  };

  useEffect(() => {
    if (loggedInUser !== null && loggedInUser !== undefined) {
      Auth.currentSession()
        .then(() => {
          setUserDetails();
          i18n.changeLanguage("en_ZM");
        })
        .catch(() => {
          history.push({ pathname: "/login" });
        });
    }
  }, [loggedInUser]);

  return (
    <AppBar component="nav" sx={{ background: "white" }}>
      <header className={styles.appHeader}>
        <Grid container>
          <Grid item xs={4}>
            {isGuestMode === false && (
              <div className={styles.menuContainer}>
                {window.location.pathname === "/consent-print-preview" ? (
                  <IconButton
                    onClick={handleClickBack}
                    color="primary"
                    aria-label="Home"
                    component="span"
                  >
                    <ArrowBackIosOutlinedIcon
                      fontSize="large"
                      style={{ color: "var(--primary-color)" }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={handleClickOpen}
                    color="primary"
                    aria-label="Home"
                    component="span"
                  >
                    <MenuIcon
                      fontSize="large"
                      style={{ color: "var(--primary-color)" }}
                    />
                  </IconButton>
                )}
              </div>
            )}
          </Grid>
          <Grid item xs={4}>
            <div className={styles.iaviLogoContainer}>
              {heading === "" ? (
                <img
                  className={styles.iaviLogo}
                  src={IAVILogoBase64}
                  alt={"IAVI - Tramslating science into global health impact."}
                />
              ) : (
                <div className={styles.participantId}>
                  <div>
                    <h1
                      style={{
                        marginTop: participant_id !== null ? "15px" : "28px",
                      }}
                    >
                      {appTitle}
                    </h1>
                  </div>
                  {participant_id !== null && (
                    <div>
                      Participant ID: {displayParticipantId()}{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp; Consent ID: {enrolment_id}
                    </div>
                  )}
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            {isGuestMode === false && (
              <div className={styles.profileAvatarContainer}>
                <Tooltip title="Account settings">
                  <IconButton
                    className={styles.profileButton}
                    onClick={handleProfileMenuClick}
                    size="small"
                    sx={{ ml: 2 }}
                  >
                    <Avatar
                      className={styles.profileAvatar}
                      sx={{ width: 32, height: 32 }}
                    >
                      {userInitials}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={profileMenu}
                  open={openProfileMenu}
                  onClose={handleProfileMenuClose}
                  onClick={handleProfileMenuClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem>
                    <Avatar />
                    {userFullName} ({siteId})
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={logout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    {t("logout")}
                  </MenuItem>
                </Menu>
              </div>
            )}

            <div className={styles.profileAvatarContainer}>
              {isPrintPreviewMode && (
                <Button
                  variant="contained"
                  className={styles.langButton}
                  onClick={handlePrint}
                  size="small"
                  sx={{ ml: 2 }}
                  color="primary"
                >
                  <LocalPrintshopOutlinedIcon />
                  <span style={{ marginLeft: "8px", marginRight: "8px" }}>
                    Print
                  </span>
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </header>
      <HandleSaveEnrolment />
    </AppBar>
  );
};

AppHeader.propTypes = {
  isGuestMode: PropTypes.bool.isRequired,
  heading: PropTypes.string,
  isPrintPreviewMode: PropTypes.bool,
};

export default AppHeader;
