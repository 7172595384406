import { API, graphqlOperation } from "aws-amplify";
import {
  createEnrolment,
  updateEnrolmentParticipant,
  updateEnrolmentGuardian,
  updateEnrolmentStatus,
} from "../../graphql/mutations";
import { getEnrolment, getEnrolments } from "../../graphql/queries";

export const apiCreateEnrolment = async (participantId) => {
  let results = { success: true, body: {}, message: "" };

  const enrolmentData = {
    PK: `PARTICIPANT#${participantId}`,
  };

  try {
    const response = await API.graphql(
      graphqlOperation(createEnrolment, {
        ConsentWithLongStorage: enrolmentData,
      })
    );

    results.body = response.data.createEnrolment;
    console.log("apiCreateEnrolment successfull.");
  } catch (err) {
    results.success = false;
    results.message = "Error creating new record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateEnrolmentStatus = async (
  participantId,
  enrolementId,
  recordStatus
) => {
  let results = { success: true, body: {}, message: "" };

  const enrolmentData = {
    PK: `PARTICIPANT#${participantId}`,
    SK: `ENROLMENT#${enrolementId}`,
    record_status: recordStatus,
  };

  try {
    const response = await API.graphql(
      graphqlOperation(updateEnrolmentStatus, {
        ConsentWithAudioTaping: enrolmentData,
      })
    );

    results.body = response.data.updateEnrolmentStatus;
    console.log("apiUpdateEnrolmentStatus successfull.");
  } catch (err) {
    results.success = false;
    results.message = "Error saving record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateEnrolmentParticipant = async (participantData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateEnrolmentParticipant, {
        ConsentWithLongStorage: participantData,
      })
    );

    results.body = response.data.updateEnrolmentParticipant;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateEnrolmentGuardian = async (guardianData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateEnrolmentGuardian, {
        ConsentWithLongStorage: guardianData,
      })
    );

    results.body = response.data.updateEnrolmentGuardian;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiGetEnrolment = async (partcipantId, enrolmentId) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getEnrolment, {
        PK: `PARTICIPANT#${partcipantId}`,
        SK: `ENROLMENT#${enrolmentId}`,
      })
    );

    results.body = response.data.getEnrolment;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetEnrolments = async (partcipantId, enrolmentId) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(graphqlOperation(getEnrolments));

    results.body = response.data.getEnrolments;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};
