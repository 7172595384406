/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getParticipant = /* GraphQL */ `
  query GetParticipant($PK: String!) {
    getParticipant(PK: $PK) {
      PK
      SK
      participant_first_name
      participant_last_name
      participant_signature
      participant_illiterate
      guardian_consent
      guardian_first_name
      guardian_last_name
      guardian_signature
      guardian_illiterate
      chosen_language
      age_category
      created_date
      created_time
      site_id
      participant_country
      participant_consent_list
    }
  }
`;
export const getEnrolment = /* GraphQL */ `
  query GetEnrolment($PK: String!, $SK: String!) {
    getEnrolment(PK: $PK, SK: $SK) {
      PK
      SK
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      lng_stor_participant_consent_1
      lng_stor_participant_consent_2
      lng_stor_participant_consent_3
      lng_stor_participant_first_name
      lng_stor_participant_last_name
      lng_stor_participant_signature
      lng_stor_participant_date
      lng_stor_participant_time
      lng_stor_participant_witness_first_name
      lng_stor_participant_witness_last_name
      lng_stor_participant_witness_signature
      lng_stor_participant_witness_date
      lng_stor_participant_witness_time
      lng_stor_participant_staff_first_name
      lng_stor_participant_staff_last_name
      lng_stor_participant_staff_signature
      lng_stor_participant_staff_date
      lng_stor_participant_staff_time
      participant_aou_results
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      lng_stor_guardian_consent_1
      lng_stor_guardian_consent_2
      lng_stor_guardian_consent_3
      lng_stor_guardian_child_first_name
      lng_stor_guardian_child_last_name
      lng_stor_guardian_first_name
      lng_stor_guardian_last_name
      lng_stor_guardian_signature
      lng_stor_guardian_date
      lng_stor_guardian_time
      lng_stor_guardian_witness_first_name
      lng_stor_guardian_witness_last_name
      lng_stor_guardian_witness_signature
      lng_stor_guardian_witness_date
      lng_stor_guardian_witness_time
      lng_stor_guardian_staff_first_name
      lng_stor_guardian_staff_last_name
      lng_stor_guardian_staff_signature
      lng_stor_guardian_staff_date
      lng_stor_guardian_staff_time
      guardian_aou_results
      record_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const downloadSignature = /* GraphQL */ `
  query DownloadSignature($input: inputSignature!) {
    downloadSignature(input: $input) {
      presign_url
    }
  }
`;
export const findParticipant = /* GraphQL */ `
  query FindParticipant($input: inputFindParticipant) {
    findParticipant(input: $input) {
      participant_id
      participant_first_name
      participant_last_name
      participant_signature
      participant_illiterate
      guardian_consent
      guardian_first_name
      guardian_last_name
      guardian_signature
      guardian_illiterate
      chosen_language
      age_category
      created_date
      created_time
      site_id
    }
  }
`;
export const verifyOTP = /* GraphQL */ `
  query VerifyOTP($input: inputVerifyOTP) {
    verifyOTP(input: $input) {
      uuid_token
      otp_code
      valid
    }
  }
`;
export const getEnrolments = /* GraphQL */ `
  query GetEnrolments {
    getEnrolments {
      PK
      SK
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      lng_stor_participant_consent_1
      lng_stor_participant_consent_2
      lng_stor_participant_consent_3
      lng_stor_participant_first_name
      lng_stor_participant_last_name
      lng_stor_participant_signature
      lng_stor_participant_date
      lng_stor_participant_time
      lng_stor_participant_witness_first_name
      lng_stor_participant_witness_last_name
      lng_stor_participant_witness_signature
      lng_stor_participant_witness_date
      lng_stor_participant_witness_time
      lng_stor_participant_staff_first_name
      lng_stor_participant_staff_last_name
      lng_stor_participant_staff_signature
      lng_stor_participant_staff_date
      lng_stor_participant_staff_time
      participant_aou_results
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      lng_stor_guardian_consent_1
      lng_stor_guardian_consent_2
      lng_stor_guardian_consent_3
      lng_stor_guardian_child_first_name
      lng_stor_guardian_child_last_name
      lng_stor_guardian_first_name
      lng_stor_guardian_last_name
      lng_stor_guardian_signature
      lng_stor_guardian_date
      lng_stor_guardian_time
      lng_stor_guardian_witness_first_name
      lng_stor_guardian_witness_last_name
      lng_stor_guardian_witness_signature
      lng_stor_guardian_witness_date
      lng_stor_guardian_witness_time
      lng_stor_guardian_staff_first_name
      lng_stor_guardian_staff_last_name
      lng_stor_guardian_staff_signature
      lng_stor_guardian_staff_date
      lng_stor_guardian_staff_time
      guardian_aou_results
      record_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getParticipants = /* GraphQL */ `
  query GetParticipants {
    getParticipants {
      PK
      SK
      participant_first_name
      participant_last_name
      participant_signature
      participant_illiterate
      guardian_consent
      guardian_first_name
      guardian_last_name
      guardian_signature
      guardian_illiterate
      chosen_language
      age_category
      created_date
      created_time
      site_id
      participant_country
      participant_consent_list
    }
  }
`;
export const getFGDA1 = /* GraphQL */ `
  query GetFGDA1 {
    getFGDA1 {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getIDIA1 = /* GraphQL */ `
  query GetIDIA1 {
    getIDIA1 {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getKIIA1 = /* GraphQL */ `
  query GetKIIA1 {
    getKIIA1 {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getPrimaryStudyDashboard = /* GraphQL */ `
  query GetPrimaryStudyDashboard {
    getPrimaryStudyDashboard {
      total_screened
      total_completed_consents
      total_failed_consents
      total_consents_minor
      total_consents_emancipated_minor
      total_consents_adults
      total_completed_consents_minor
      total_completed_consents_emancipated_minor
      total_completed_consents_adults
      total_failed_consents_minor
      total_failed_consents_emancipated_minor
      total_failed_consents_adults
      total_literate_participants
      total_illiterate_participants
      total_literate_guardians
      total_illiterate_guardians
      participant_aou_results
      guardian_aou_results
      zambia_total_participants
      south_africa_total_participants
      uganda_total_participants
    }
  }
`;
