import React from "react";
import styles from "./BigIconButton.module.css";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const BigIconButton = ({ icon, url, label, id }) => {
  const history = useHistory();

  const buttonClicked = () => {
    history.push(url);
  };

  return (
    <div
      className={styles.bigButton}
      onClick={buttonClicked}
      onKeyDown={buttonClicked}
      tabIndex="0"
      role="button"
      id={id}
    >
      <div className={styles.bigButtonIcon}>
        <img src={icon} />
      </div>
      <div className={styles.buttonLabel}>{label}</div>
    </div>
  );
};

BigIconButton.propTypes = {
  icon: PropTypes.any,
  url: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
};

export default BigIconButton;
