import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./aws-config";
import LoginPage from "./pages/Login";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ForgotPasswordConfirm from "./pages/ForgotPasswordConfirm";
import HomePage from "./pages/HomePage";
import { setIsDeviceOnline } from "./store/slices/settingsSlice";
import useNavigatorOnLine from "./hooks/get_online_status";
import { useDispatch } from "react-redux";
import ViewAllCosentData from "./pages/ViewAllCosentData";
import MainConsents from "./pages/MainConsents";
import StepFinalReview from "./pages/NewEnrolment/StepFinalReview";

Amplify.configure(awsconfig);

function App() {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const isOnline = useNavigatorOnLine();
  const dispatch = useDispatch();

  useEffect(() => {
    Auth.currentSession()
      .then(() => {
        setIsUserAuthenticated(true);
        console.log("isUserAuthenticated");
      })
      .catch(() => {
        if (isOnline) {
          setIsUserAuthenticated(false);
        } else {
          setIsUserAuthenticated(true);
        }
      });
  }, []);

  useEffect(() => {
    dispatch(setIsDeviceOnline(isOnline));
  }, [isOnline]);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return isUserAuthenticated === true ? (
              <Redirect to="/home" />
            ) : (
              <Redirect to="/login" />
            );
          }}
        />
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/forgot-password">
          <ForgotPasswordPage />
        </Route>
        <Route path="/confirm-forgot-password">
          <ForgotPasswordConfirm />
        </Route>
        <Route path="/home">
          <HomePage />
        </Route>
        <Route path="/view-all-consent">
          <ViewAllCosentData />
        </Route>
        <Route path="/main-consents">
          <MainConsents />
        </Route>
        <Route path="/consent-print-preview">
          <StepFinalReview />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
