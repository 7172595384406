import { API, graphqlOperation } from "aws-amplify";
import { sendSMSOTP } from "../../graphql/mutations";
import { verifyOTP } from "../../graphql/queries";

export const apiSendOTP = async (params) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(sendSMSOTP, { input: params })
    );

    results.body = response.data.sendSMSOTP;
    console.log("apiCreateEnrolment successfull.");
  } catch (err) {
    results.success = false;
    results.message = "Error creating new record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiVerifyOTP = async (params) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(verifyOTP, { input: params })
    );

    results.body = response.data.verifyOTP;
    results.success = response.data.verifyOTP.valid;
  } catch (err) {
    results.success = false;
    results.message = "Error saving record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};
