/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createParticipant = /* GraphQL */ `
  mutation CreateParticipant($Participant: inputCreateParticipant) {
    createParticipant(Participant: $Participant)
  }
`;
export const updateParticipant = /* GraphQL */ `
  mutation UpdateParticipant($Participant: inputUpdateParticipant) {
    updateParticipant(Participant: $Participant)
  }
`;
export const createEnrolment = /* GraphQL */ `
  mutation CreateEnrolment($ConsentWithLongStorage: inputCreateEnrolment) {
    createEnrolment(ConsentWithLongStorage: $ConsentWithLongStorage)
  }
`;
export const updateEnrolmentParticipant = /* GraphQL */ `
  mutation UpdateEnrolmentParticipant(
    $ConsentWithLongStorage: inputUpdateEnrolmentParticipant
  ) {
    updateEnrolmentParticipant(ConsentWithLongStorage: $ConsentWithLongStorage)
  }
`;
export const updateEnrolmentGuardian = /* GraphQL */ `
  mutation UpdateEnrolmentGuardian(
    $ConsentWithLongStorage: inputUpdateEnrolmentGuardian
  ) {
    updateEnrolmentGuardian(ConsentWithLongStorage: $ConsentWithLongStorage)
  }
`;
export const updateFGDA1Guardian = /* GraphQL */ `
  mutation UpdateFGDA1Guardian(
    $ConsentWithAudioTaping: inputUpdateFGDA1Guardian
  ) {
    updateFGDA1Guardian(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const updateFGDA1Participant = /* GraphQL */ `
  mutation UpdateFGDA1Participant(
    $ConsentWithAudioTaping: inputUpdateFGDA1Participant
  ) {
    updateFGDA1Participant(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const updateEnrolmentStatus = /* GraphQL */ `
  mutation UpdateEnrolmentStatus(
    $ConsentWithLongStorage: inputUpdateEnrolmentStatus
  ) {
    updateEnrolmentStatus(ConsentWithLongStorage: $ConsentWithLongStorage)
  }
`;
export const updateIntensiveLongitudinalSubStudyGuardian = /* GraphQL */ `
  mutation UpdateIntensiveLongitudinalSubStudyGuardian(
    $ConsentWithLongStorage: inputUpdateIntensiveLongitudinalSubStudyGuardian
  ) {
    updateIntensiveLongitudinalSubStudyGuardian(
      ConsentWithLongStorage: $ConsentWithLongStorage
    )
  }
`;
export const updateIntensiveLongitudinalSubStudyParticipant = /* GraphQL */ `
  mutation UpdateIntensiveLongitudinalSubStudyParticipant(
    $ConsentWithLongStorage: inputUpdateIntensiveLongitudinalSubStudyParticipant
  ) {
    updateIntensiveLongitudinalSubStudyParticipant(
      ConsentWithLongStorage: $ConsentWithLongStorage
    )
  }
`;
export const updateIDIA1Guardian = /* GraphQL */ `
  mutation UpdateIDIA1Guardian(
    $ConsentWithAudioTaping: inputUpdateIDIA1Guardian
  ) {
    updateIDIA1Guardian(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const updateIDIA1Participant = /* GraphQL */ `
  mutation UpdateIDIA1Participant(
    $ConsentWithAudioTaping: inputUpdateIDIA1Participant
  ) {
    updateIDIA1Participant(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const updateKIIA1Guardian = /* GraphQL */ `
  mutation UpdateKIIA1Guardian(
    $ConsentWithAudioTaping: inputUpdateKIIA1Guardian
  ) {
    updateKIIA1Guardian(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const updateKIIA1Participant = /* GraphQL */ `
  mutation UpdateKIIA1Participant(
    $ConsentWithAudioTaping: inputUpdateKIIA1Participant
  ) {
    updateKIIA1Participant(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const uploadSignature = /* GraphQL */ `
  mutation UploadSignature($input: inputSignature!) {
    uploadSignature(input: $input) {
      presign_url
      presign_fields
      object_path
    }
  }
`;
export const deleteSignature = /* GraphQL */ `
  mutation DeleteSignature($input: inputDeleteSignature!) {
    deleteSignature(input: $input)
  }
`;
export const createIntensiveLongitudinalSubStudy = /* GraphQL */ `
  mutation CreateIntensiveLongitudinalSubStudy(
    $ConsentWithLongStorage: inputCreateIntensiveLongitudinalSubStudy
  ) {
    createIntensiveLongitudinalSubStudy(
      ConsentWithLongStorage: $ConsentWithLongStorage
    )
  }
`;
export const createFGDA1SubStudy = /* GraphQL */ `
  mutation CreateFGDA1SubStudy($ConsentWithAudioTaping: inputFGDA1SubStudy) {
    createFGDA1SubStudy(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const createIDIA1SubStudy = /* GraphQL */ `
  mutation CreateIDIA1SubStudy($ConsentWithAudioTaping: inputIDIA1SubStudy) {
    createIDIA1SubStudy(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const createKIIA1SubStudy = /* GraphQL */ `
  mutation CreateKIIA1SubStudy($ConsentWithAudioTaping: inputKIIA1SubStudy) {
    createKIIA1SubStudy(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const createICSTSubStudy = /* GraphQL */ `
  mutation CreateICSTSubStudy($ConsentWithAudioTaping: inputICSTSubStudy) {
    createICSTSubStudy(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const sendSMSOTP = /* GraphQL */ `
  mutation SendSMSOTP($input: inputSendSMSOTP) {
    sendSMSOTP(input: $input)
  }
`;
