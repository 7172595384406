import React from "react";
import styles from "./SignatureImageView.module.css";
import PropTypes from "prop-types";
import ClearIcon from "@mui/icons-material/Clear";

const SignatureImageView = ({ signature = "" }) => {
  return (
    <div>
      {signature === null && (
        <div className={styles.unableToSign}>
          <ClearIcon />
          <p>Unable to sign</p>
        </div>
      )}
      {signature !== null && (
        <div className={styles.signatureImageBox}>
          <img width="100%" src={signature} />
        </div>
      )}
    </div>
  );
};

SignatureImageView.propTypes = {
  signature: PropTypes.string,
};

export default SignatureImageView;
