import React, { useState, useEffect } from "react";
import styles from "./LongStorageAdultParticipantView.module.css";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import SignatureImageView from "../../../../components/SignatureImageView";

const LongStorageAdultParticipantView = () => {
  const isParticipantIlliterate = useSelector(
    (state) => state.settings.participantIlliterate
  );
  const enrolmentState = useSelector((state) => state.enrolment);
  const [participantConsentObj, setParticipantConsentObj] = useState({});

  useEffect(() => {
    setParticipantConsentObj({
      participantFirstName: enrolmentState.lng_stor_participant_first_name,
      participantLastName: enrolmentState.lng_stor_participant_last_name,
      consentDate: enrolmentState.lng_stor_participant_date,
      consentTime: enrolmentState.lng_stor_participant_time,
      signature: enrolmentState.lng_stor_participant_signature,
    });
  }, [enrolmentState]);

  return (
    <div className={styles.pageViewBox}>
      <h2 className={styles.intro}>
        Consent form for long term storage, genetic and future testing.
      </h2>

      <div className={styles.consentText}>
        <ol>
          <li>
            I give permission for the transport of samples to laboratories in
            South Africa and internationally, storage and future use of my
            samples by the investigators under the conditions described in this
            information sheet, including specified genetic testing. I understand
            that none of the information resulting from these tests will be
            shared with me or any other persons or entities not affiliated
            (linked) to this study.
          </li>
          <li>
            I give permission for the transport of my samples and information to
            laboratories in South Africa and internationally, storage and future use of 
            my samples by the investigators under the conditions
            described in this information sheet, except specified genetic
            testing.
          </li>
          <li>
            I wish to take a copy of the signed and dated informed consent form
            with me
          </li>
        </ol>
        <p>
          I understand that I can change my mind at any stage, and it will not
          affect me in any way. I have had sufficient opportunity to ask
          questions and (of my own free will) provide my samples for long term
          storage, and future testing by signing this consent form.
        </p>
      </div>

      <div className={styles.consentForm}>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Participant First Name </label>
                <div className={styles.fieldText}>
                  {participantConsentObj.participantFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Participant Last Name </label>
                <div className={styles.fieldText}>
                  {participantConsentObj.participantLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>
                  Participant{" "}
                  {isParticipantIlliterate ? "acknowledgement" : "Signature"}
                </label>
                <SignatureImageView
                  signature={participantConsentObj.signature}
                />
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Date</label>
                <div className={styles.fieldText}>
                  {participantConsentObj.consentDate}
                </div>
              </FormControl>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Time</label>
                <div className={styles.fieldText}>
                  {participantConsentObj.consentTime}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default LongStorageAdultParticipantView;
