import React, { useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import { useHistory } from "react-router-dom";
import { login } from "../../services/users.service";
import { useSnackbar } from "notistack";
import PreLoader from "../../components/common/PreLoader";
import LoginForm from "../../components/LoginForm/LoginForm";
import { completeNewPassword } from "../../services/users.service";
import CompleteSignupForm from "../../components/CompleteSignupForm";
import { Auth } from "aws-amplify";
import SetupLoginMFA from "../../components/SetupLoginMFA";
import ConfirmLoginMFAOtp from "../../components/ConfirmLoginMFAOtp";
// import { signOut } from "../../services/users.service";
import { apiSendOTP } from "../../services/api/userApi.service";
import { signOut } from "../../services/users.service";

const LoginPage = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [Loading, setLoading] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showLoginMFASetup, setShowLoginMFASetup] = useState(false);
  const [showCompleteLogin, setShowCompleteLogin] = useState(false);
  const [showOTPConfirm, setShowOTPConfirm] = useState(false);
  const [MFACode, setMFACode] = useState("");
  const [username, setUsername] = useState("");
  const [cognitoUser, setcognitoUser] = useState(null);
  const [mfaType, setMfaType] = useState("");
  const [loginObj, setLoginObj] = useState({});
  const [SMSVerifyToken, setSMSVerifyToken] = useState(null);

  const handleCompleteSignupForm = async (values) => {
    setLoading(true);

    const response = await completeNewPassword(cognitoUser, values.password, {
      "custom:first_name": values.firstName,
      "custom:last_name": values.lastName,
      "custom:user_cache": "5555",
    });

    if (response.success) {
      setLoading(false);
      history.push("/home");
    } else {
      setLoading(false);
      let _message =
        "Unable to submit your request. Please contact an administrator.";
      if (response.type === "UsernameExistsException") {
        _message = "An account with this email address already exists.";
      } else if (response.type === "InvalidPasswordException") {
        _message =
          "Password did not conform with policy: Password must contain a minimum of 14 characters including 1 Uppercase, 1 Lowercase, 1 Number and 1 Special character (^ $ * . [ ] { } ( ) ? -  ! @ # % & /  , > < ' : ; | _ ~ ` + =)";
      }
      enqueueSnackbar(_message, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const handleNewPasswordRequired = async (cognitoUser) => {
    setcognitoUser(cognitoUser);
    setShowLoginForm(false);
    setShowCompleteLogin(true);
  };

  const handleMFASetup = async (cognitoUser) => {
    setcognitoUser(cognitoUser);
    Auth.setupTOTP(cognitoUser).then((code) => {
      console.log(code);
      setMFACode(code);
    });
  };

  const completedMFASetup = async () => {
    const response = await login(loginObj.email, loginObj.password);

    if (response.success) {
      history.push("/home");
    }
  };

  const handleLogin = async (values) => {
    setLoading(true);
    setUsername(values.email);
    setLoginObj(values);
    const response = await login(values.email, values.password);

    if (response.success) {
      setLoading(false);

      if (
        response.body.challengeName &&
        response.body.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        handleNewPasswordRequired(response.body);
      } else if (
        response.body.challengeName &&
        response.body.challengeName === "MFA_SETUP"
      ) {
        setShowLoginMFASetup(true);
        setShowLoginForm(false);
        setShowCompleteLogin(false);
        handleMFASetup(response.body);
      } else if (
        response.body.challengeName &&
        response.body.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        setMfaType(response.body.challengeName);
        setShowOTPConfirm(true);
        setShowLoginMFASetup(false);
        setShowLoginForm(false);
        setShowCompleteLogin(false);
        handleMFASetup(response.body);
      } else if (response.body.challengeName === "SMS_MFA") {
        setMfaType(response.body.challengeName);
        setShowOTPConfirm(true);
        setShowLoginMFASetup(false);
        setShowLoginForm(false);
        setShowCompleteLogin(false);
        handleMFASetup(response.body);
      } else {
        if (process.env.REACT_APP_OWN_SMS_MFA == "true") {
          setLoading(true);
          const sendResults = await apiSendOTP({ email: values.email });

          if (sendResults.success) {
            setShowOTPConfirm(true);
            setShowLoginMFASetup(false);
            setShowLoginForm(false);
            setShowCompleteLogin(false);
            setSMSVerifyToken(sendResults.body);
          }
          setLoading(false);
        } else {
          history.push("/home");
        }
      }
    } else {
      setLoading(false);
      let _message =
        "Unable to submit your request. Please contact an administrator.";

      if (response.type === "AUTH_PASSWORD_EXPIRED") {
        _message = "Unable to login. Please contact an administrator.";
      } else if (response.type === "UserNotFoundException") {
        _message = "Invalid details provided.";
      } else if (response.type === "NotAuthorizedException") {
        _message = "Incorrect email or password.";
      }
      enqueueSnackbar(_message, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  useEffect(() => {
    signOut();
  }, []);

  return (
    <>
      <AppHeader isGuestMode={true} />
      {showLoginForm && <LoginForm handleLogin={handleLogin} />}
      {showCompleteLogin && (
        <CompleteSignupForm
          handleCompleteSignupForm={handleCompleteSignupForm}
        />
      )}
      {showLoginMFASetup && (
        <SetupLoginMFA
          code={MFACode}
          username={username}
          completedMFASetup={completedMFASetup}
          cognitoUser={cognitoUser}
        />
      )}

      {showOTPConfirm && (
        <ConfirmLoginMFAOtp
          completedMFASetup={completedMFASetup}
          cognitoUser={cognitoUser}
          mfaType={mfaType}
          SMSVerifyToken={SMSVerifyToken}
          setLoading={setLoading}
        />
      )}
      <PreLoader show={Loading} />
    </>
  );
};

export default LoginPage;
