import React, { useState, useEffect } from "react";
import styles from "./ConsentMinorParentView.module.css";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import SignatureImageView from "../../../../components/SignatureImageView";

const ConsentMinorParentView = () => {
  const isParentIlliterate = useSelector(
    (state) => state.settings.parentIlliterate
  );
  const enrolmentState = useSelector((state) => state.enrolment);
  const [parentConsentObj, setParentConsentObj] = useState({});

  useEffect(() => {
    setParentConsentObj({
      childFirstName: enrolmentState.consent_guardian_child_first_name,
      childLastName: enrolmentState.consent_guardian_child_last_name,
      parentFirstName: enrolmentState.consent_guardian_first_name,
      parentLastName: enrolmentState.consent_guardian_last_name,
      consentDate: enrolmentState.consent_guardian_date,
      consentTime: enrolmentState.consent_guardian_time,
      signature: enrolmentState.consent_guardian_signature,
    });
  }, [enrolmentState]);

  return (
    <div className={styles.pageViewBox}>
      <h2 className={styles.intro}>CONSENT FORM FOR PARENT / LEGAL GUARDIAN</h2>

      <div className={styles.consentText}>
        <ol>
          <li>
            I agree for my child to take part in the research project entitled
            “A multisite, prospective, observational cohort study to engage and
            enroll adolescent girls and young women for future HIV vaccine and
            antibody clinical trials (MAGY)” and that I can be contacted by
            study staff during my participation.
          </li>
          <li>
            I understand that my child’s participation in this study is
            voluntary and that I may decide not for my child to participate. I
            also understand that if, for any reason, I wish for my child to
            discontinue participation in this study at any time, I will be free
            to do so.
          </li>
          <li>
            I understand that as a participant in this study, my child’s
            identity, medical records, and data relating to this research study
            will be kept confidential, except as required by law.
          </li>
          <li>
            I understand that if I my child is injured because of participating
            in this research study, appropriate medical care will be provided to
            my child as detailed in this consent form.
          </li>
          <li>
            I have read this Informed Consent Document and have discussed with
            the study investigators the purpose of the study, the procedures
            involved, the number of visits to the study clinic, the anticipated
            risks and benefits involved, participant safety procedures and
            protection of rights of study participants. I have been given the
            opportunity to ask questions, which have been answered to my
            satisfaction. If I have any questions concerning my rights as a
            research subject in this study, I may contact any of the study
            investigators at any time point.
          </li>
          <li>
            I agree that the study team use the details that I have provided
            (address, telephone number, etc.) to locate me as needed during the
            study.
          </li>
          <li>
            I agree for my child to be transported by the study staff for my
            child&apos;s study visits, as needed.
          </li>
          <li>
            I agree that my child&apos;s personal data can be stored in the
            country of collection and securely transferred to IAVI (in the USA),
            and IAVI’s processors in the UK and the European Union for further
            processing, and secure storage for scientific research purposes
            (data will be de-identified/pseudonymized wherever possible).
          </li>
          <li>
            I agree for my child to be contacted about future research
            opportunities.
          </li>
          <li>
            I have been offered a copy of the signed and dated informed consent
            form to take home with me.
          </li>
        </ol>
        <p>
          I understand that I can change my mind at any stage, and it will not
          affect my child in any way. I have had sufficient opportunity to ask
          questions and (of my own free will) provide my consent for my child to
          participate in this study by signing this consent form.
        </p>
      </div>

      <div className={styles.consentForm}>
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Child First Name</label>
                <div className={styles.fieldText}>
                  {parentConsentObj.childFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Child Last Name</label>
                <div className={styles.fieldText}>
                  {parentConsentObj.childLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Parent / Legal guardian First Name </label>
                <div className={styles.fieldText}>
                  {parentConsentObj.parentFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Parent / Legal guardian Last Name </label>
                <div className={styles.fieldText}>
                  {parentConsentObj.parentLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>
                  Parent / Legal guardian{" "}
                  {isParentIlliterate ? "acknowledgement" : "Signature"}
                </label>
                <SignatureImageView signature={parentConsentObj.signature} />
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Date</label>
                <div className={styles.fieldText}>
                  {parentConsentObj.consentDate}
                </div>
              </FormControl>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Time</label>
                <div className={styles.fieldText}>
                  {parentConsentObj.consentTime}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default ConsentMinorParentView;
