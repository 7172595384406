import { Auth } from "aws-amplify";

export const getUserSiteId = async () => {
  let user = null;
  try {
    user = await Auth.currentAuthenticatedUser();
    const siteId = user.attributes["custom:site_id"];

    return siteId;
  } catch (ex) {
    console.log(ex);
  }
};

export const getUserFullName = async () => {
  let user = null;
  try {
    user = await Auth.currentAuthenticatedUser();

    const firstName = user.attributes["custom:first_name"];
    const lastName = user.attributes["custom:last_name"];

    return `${firstName} ${lastName}`;
  } catch (ex) {
    console.log(ex);
  }
};

export const getUserGroup = async () => {
  let user = null;
  try {
    user = await Auth.currentAuthenticatedUser();

    const group =
      user.signInUserSession.accessToken.payload["cognito:groups"][0];

    return group;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Cognito user login
 * @param {*} username - username
 * @param {*} password - password
 * @returns cognito user object
 */
export const login = async (username, password) => {
  let response = { success: true, body: {}, message: "" };
  try {
    const user = await Auth.signIn(username, password);
    response.body = user;
  } catch (error) {
    response.success = false;
    response.message = error.message;
    response.type = error.code;

    if (error.message.indexOf("Temporary password has expired") > -1) {
      response.type = "AUTH_PASSWORD_EXPIRED";
    }
  }
  return response;
};

export const completeNewPassword = async (
  cognitoUser,
  password,
  attributes
) => {
  let response = { success: true, body: {}, message: "" };
  try {
    const user = await Auth.completeNewPassword(
      cognitoUser,
      password,
      attributes
    );
    response.body = user;
  } catch (error) {
    response.success = false;
    response.message = error.message;
    response.type = error.code;
  }
  return response;
};

/**
 * Cognito forgot password
 * @param {*} username - username
 */
export const forgotPassword = async (username) => {
  let response = { success: true, body: {}, message: "" };
  await Auth.forgotPassword(username)
    .then((data) => {
      response.body = data;
      console.log(data);
    })
    .catch((error) => {
      response.success = false;
      response.message = error.message;
      response.type = error.code;
    });

  return response;
};

/**
 * Cognito confirm forgot password
 * @param {*} username - username
 */
export const confirmForgotPassword = async (username, code, newPassword) => {
  let response = { success: true, body: {}, message: "" };
  await Auth.forgotPasswordSubmit(username, code, newPassword)
    .then((data) => {
      response.body = data;
      console.log(data);
    })
    .catch((error) => {
      response.success = false;
      response.message = error.message;
      response.type = error.code;
    });
  return response;
};

/**
 * Cognito user signout
 */
export const signOut = async () => {
  let response = { success: true, body: {}, message: "" };
  try {
    await Auth.signOut({ global: true });
  } catch (error) {
    response.success = false;
    response.message = error.message;
    console.log("error signing out: ", error);
    response.type = error.code;
  }
  return response;
};
