import React, { useState, useEffect } from "react";
import styles from "./LongStorageMinorParentView.module.css";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import SignatureImageView from "../../../../components/SignatureImageView";

const LongStorageMinorParentView = () => {
  const isParentIlliterate = useSelector(
    (state) => state.settings.parentIlliterate
  );
  const enrolmentState = useSelector((state) => state.enrolment);
  const [parentConsentObj, setParentConsentObj] = useState({});

  useEffect(() => {
    setParentConsentObj({
      childFirstName: enrolmentState.lng_stor_guardian_child_first_name,
      childLastName: enrolmentState.lng_stor_guardian_child_last_name,
      parentFirstName: enrolmentState.lng_stor_guardian_first_name,
      parentLastName: enrolmentState.lng_stor_guardian_last_name,
      consentDate: enrolmentState.lng_stor_guardian_date,
      consentTime: enrolmentState.lng_stor_guardian_time,
      signature: enrolmentState.lng_stor_guardian_signature,
    });
  }, [enrolmentState]);

  return (
    <div className={styles.pageViewBox}>
      <h2 className={styles.intro}>
        Consent form for long term storage, genetic and future testing.
      </h2>

      <div className={styles.consentText}>
        <ol>
          <li>
            I give permission for the transport of samples to laboratories in
            South Africa and internationally, storage and future use of my
            child’s samples by the investigators under the conditions described
            in this information sheet, including specified genetic testing. I
            understand that none of the information resulting from these tests
            will be shared with me or any other persons or entities not
            affiliated (linked) to this study.
          </li>
          <li>
            I give permission for the transport of my child’s samples and
            information to laboratories in South Africa and internationally,
            storage and future use of my child’s samples by the investigators
            under the conditions described in this information sheet, except
            specified genetic testing.
          </li>
          <li>
            I wish to take a copy of the signed and dated informed consent form
            with me.
          </li>
        </ol>
        <p>
          I understand that I can change my mind at any stage, and it will not
          affect me in any way. I have had sufficient opportunity to ask
          questions and (of my own free will) provide my child’s samples for
          long term storage, and future testing by signing this consent form.
        </p>
      </div>

      <div className={styles.consentForm}>
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Child First Name</label>
                <div className={styles.fieldText}>
                  {parentConsentObj.childFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Child Last Name</label>
                <div className={styles.fieldText}>
                  {parentConsentObj.childLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Parent / Legal guardian First Name </label>
                <div className={styles.fieldText}>
                  {parentConsentObj.parentFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Parent / Legal guardian Last Name </label>
                <div className={styles.fieldText}>
                  {parentConsentObj.parentLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>
                  Parent / Legal guardian{" "}
                  {isParentIlliterate ? "acknowledgement" : "Signature"}
                </label>
                <SignatureImageView signature={parentConsentObj.signature} />
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Date</label>
                <div className={styles.fieldText}>
                  {parentConsentObj.consentDate}
                </div>
              </FormControl>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>Time</label>
                <div className={styles.fieldText}>
                  {parentConsentObj.consentTime}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default LongStorageMinorParentView;
