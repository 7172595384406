/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import styles from "./HomePage.module.css";
import PreLoader from "../../components/common/PreLoader";
import AppFooter from "../../components/AppFooter/AppFooter";
import icoEnrolments from "../../assets/icon_enrolments.png";
import icoParticipants from "../../assets/icon_participants.png";
import BigIconButton from "../../components/common/BigIconButton";
import { useTranslation } from "react-i18next";
import enrolmentFormsData from "../../data/consent_forms";
import {
  resetSettings,
  setEnrolmentForms,
  setTriggerEvent,
} from "../../store/slices/settingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { resetImpartialWitnessForm } from "../../store/slices/impartialWitnessFormSlice";
import { resetPersonObtainingForm } from "../../store/slices/personObtainingFormSlice";
import { resetAou } from "../../store/slices/aouSlice";
import { resetEnrolment } from "../../store/slices/enrolmentSlice";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { setLoggedInUser } from "../../store/slices/settingsSlice";
import { Auth } from "aws-amplify";

const HomePage = () => {
  const [Loading] = useState(false);
  const [t] = useTranslation("common");
  const { isDeviceOnline, loggedInUser } = useSelector(
    (state) => state.settings
  );
  const { offlineEnrolments } = useSelector((state) => state.offlineData);
  const dispatch = useDispatch();

  const setCurrentUserDetails = async () => {
    let user = null;
    try {
      user = await Auth.currentAuthenticatedUser();

      const firstName = user.attributes["custom:first_name"];
      const lastName = user.attributes["custom:last_name"];
      const group =
        user.signInUserSession.accessToken.payload["cognito:groups"][0];
      const siteId = user.attributes["custom:site_id"];

      const _currentUserObj = {
        firstName,
        lastName,
        group,
        siteId,
      };

      dispatch(setLoggedInUser(_currentUserObj));
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    dispatch(resetSettings());
    dispatch(setEnrolmentForms(enrolmentFormsData));
    dispatch(resetImpartialWitnessForm());
    dispatch(resetPersonObtainingForm());
    dispatch(resetAou());
    dispatch(resetEnrolment());
    dispatch(setTriggerEvent(""));

    if (isDeviceOnline === true && loggedInUser === null) {
      setCurrentUserDetails();
    }
  }, []);

  return (
    <>
      <AppHeader isGuestMode={false} />
      <Container maxWidth="md">
        <center>
          <h1 className={styles.appTitle}>E-Consent Monitoring</h1>
        </center>
        <div className={styles.mainMenu}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs>
                <BigIconButton
                  icon={icoEnrolments}
                  url="/main-consents"
                  label={`Main Consents`}
                  id="button_new_consent"
                />
              </Grid>

              <Grid item xs>
                <BigIconButton
                  icon={icoParticipants}
                  url="/participants"
                  label={`Participants`}
                  id="button_manage_offline_data"
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      </Container>
      <AppFooter />
      <PreLoader show={Loading} />
    </>
  );
};

export default HomePage;
