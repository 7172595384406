import React, { useEffect, useState } from "react";
import PreLoader from "../../../components/common/PreLoader";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import {
  saveParticipantUserData,
  saveEnrolmentParticipantData,
  saveEnrolmentGuardianData,
  saveEnrolmentStatus,
} from "../../../services/enrolment.service";
import { setTriggerEvent } from "../../../store/slices/settingsSlice";
import { setOfflineEnrolments } from "../../../store/slices/offlineDataSlice";
const HandleSaveEnrolment = () => {
  const [Loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    enrolmentForms,
    currentFormIndex,
    triggerEvent,
    recordStatus,
    isDeviceOnline,
  } = useSelector((state) => state.settings);

  const enrolmentState = useSelector((state) => state.enrolment);
  const settingsState = useSelector((state) => state.settings);
  const { offlineEnrolments } = useSelector((state) => state.offlineData);
  const { guardianResults, participantResults } = useSelector(
    (state) => state.aou
  );

  const showSnackBar = (data) => {
    enqueueSnackbar(data.message, {
      variant: data.variant,
    });
  };

  const _showSuccessMessage = (success) => {
    showSnackBar({
      message: success
        ? "Enrolment updated successfully."
        : "System error. Please contact administrator.",
      variant: "success",
    });
  };

  const saveEnrolment = async (status) => {
    if (currentFormIndex === null) {
      history.push("/home");
    }

    const _pageType =
      currentFormIndex === 0
        ? enrolmentForms[currentFormIndex].type
        : enrolmentForms[currentFormIndex - 1].type;

    setLoading(true);

    const participantUpdateResults = await saveParticipantUserData(
      enrolmentState,
      settingsState,
      isDeviceOnline
    );

    const saveEnrolmentStatusSuccess = await saveEnrolmentStatus(
      enrolmentState.participant_id,
      enrolmentState.enrolment_id,
      recordStatus
    );

    if (_pageType === "MINOR_PARENT" || _pageType === "MINOR_PARTICIPANT") {
      const updateGuardianSuccess = await saveEnrolmentGuardianData(
        enrolmentState,
        settingsState,
        guardianResults,
        isDeviceOnline
      );
      const updateParticipantSuccess = await saveEnrolmentParticipantData(
        enrolmentState,
        settingsState,
        participantResults,
        isDeviceOnline
      );
      setLoading(false);

      _showSuccessMessage(
        updateGuardianSuccess &&
          updateParticipantSuccess &&
          participantUpdateResults &&
          saveEnrolmentStatusSuccess
      );
    }

    if (_pageType === "EMANCIPATED_MINOR" || _pageType === "ADULT_18_PLUS") {
      const updateParticipantSuccess = await saveEnrolmentParticipantData(
        enrolmentState,
        settingsState,
        participantResults,
        isDeviceOnline
      );
      setLoading(false);

      _showSuccessMessage(
        updateParticipantSuccess &&
          participantUpdateResults &&
          saveEnrolmentStatusSuccess
      );
    }

    dispatch(setTriggerEvent(""));

    setTimeout(() => {
      if (
        status === "SAVE_ENROLMENT_PASSED" ||
        status === "SAVE_ENROLMENT_FAILED"
      ) {
        history.push("/home");
      }
    }, 1000);
  };

  const saveEnrolmentOffline = async (status) => {
    if (currentFormIndex === null) {
      history.push("/home");
    } else {
      setLoading(true);

      const _enrolmentState = JSON.parse(JSON.stringify(enrolmentState));
      const _settingsState = JSON.parse(JSON.stringify(settingsState));
      const _guardianResults = JSON.parse(JSON.stringify(guardianResults));
      const _participantResults = JSON.parse(
        JSON.stringify(participantResults)
      );
      const _enrolmentData = {
        enrolment: _enrolmentState,
        settings: _settingsState,
        aouGuardianResults: _guardianResults,
        aouParticipantResults: _participantResults,
      };

      const _offlineEnrolments = JSON.parse(JSON.stringify(offlineEnrolments));
      _offlineEnrolments.push(_enrolmentData);
      dispatch(setOfflineEnrolments(_offlineEnrolments));

      dispatch(setTriggerEvent(""));
      _showSuccessMessage(true);

      setTimeout(() => {
        if (
          status === "SAVE_ENROLMENT_PASSED" ||
          status === "SAVE_ENROLMENT_FAILED"
        ) {
          setLoading(false);
          history.push("/home");
        }
      }, 1000);
    }
  };

  useEffect(() => {
    if (
      triggerEvent === "SAVE_ENROLMENT_PASSED" ||
      triggerEvent === "SAVE_ENROLMENT_FAILED"
    ) {
      if (isDeviceOnline) {
        saveEnrolment(triggerEvent);
      } else {
        saveEnrolmentOffline(triggerEvent);
      }
    }
  }, [triggerEvent]);

  return <PreLoader show={Loading} />;
};

export default HandleSaveEnrolment;
