import { API, graphqlOperation } from "aws-amplify";
import { createParticipant, updateParticipant } from "../../graphql/mutations";

export const apiCreateParticipant = async (siteId) => {
  let results = { success: true, body: {}, message: "" };

  const participantData = {
    site_id: siteId,
  };

  try {
    const response = await API.graphql(
      graphqlOperation(createParticipant, {
        Participant: participantData,
      })
    );

    results.body = response.data.createParticipant;
    console.log("apiCreateParticipant successfull.");
  } catch (err) {
    results.success = false;
    results.message = "Error creating new record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateParticipant = async (participantData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateParticipant, {
        Participant: participantData,
      })
    );

    results.body = response;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error creating new record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};
